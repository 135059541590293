import { CommonModule, isPlatformServer } from '@angular/common';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ICON_CONFIG, IconConfigInterface } from './interfaces/icon.interface';

@NgModule({
  imports: [CommonModule],
})
export class SharedUiIconsModule {
  private icons: [name: string, path: string][] = [
    ['insights', 'assets/ui-icons/insights.svg'],
    ['business-card', 'assets/ui-icons/business-card.svg'],
    ['mm-logo', 'assets/ui-icons/mm-logo.svg'],
    ['social-facebook', 'assets/ui-icons/social/facebook.svg'],
    ['social-instagram', 'assets/ui-icons/social/instagram.svg'],
    ['social-linkedin', 'assets/ui-icons/social/linkedin.svg'],
    ['social-pinterest', 'assets/ui-icons/social/pinterest.svg'],
    ['social-tiktok', 'assets/ui-icons/social/tiktok.svg'],
    ['social-x', 'assets/ui-icons/social/x.svg'],
    ['social-xing', 'assets/ui-icons/social/xing.svg'],
    ['social-youtube', 'assets/ui-icons/social/youtube.svg'],
    ['social-facebook-sw', 'assets/ui-icons/social/facebook-sw.svg'],
    ['social-linkedin-sw', 'assets/ui-icons/social/linkedin-sw.svg'],
    ['social-xing-sw', 'assets/ui-icons/social/xing-sw.svg'],
  ];

  constructor(
    @Inject(ICON_CONFIG) private iconConfig: IconConfigInterface,
    @Inject(PLATFORM_ID) private platformId: string,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    const domain = isPlatformServer(this.platformId) ? this.iconConfig.domain + '/' : '';
    this.icons.forEach((icon) => {
      const [name, path] = icon;
      this.iconRegistry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(domain + path));
    });
  }
}
